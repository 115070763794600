import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/layout"
import { MdSend } from "react-icons/md"
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl"
//import Image from "../components/image"
import SEO from "../components/seo"
//import Project from "./projects"
//import Lista from "../components/listData"

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />

    <section className="heroPage">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="copy">
              <p className="sub">
                <FormattedMessage id="heroPage.subtitle" />
              </p>
              <p className="title">
                <FormattedMessage id="heroPage.title" />
              </p>
              <p className="desc">
                <FormattedMessage id="heroPage.description" />
              </p>
              <div className="ctn_line">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-data"
                  href="https://www.instagram.com/diachury/"
                >
                  <img src={"/instagram.svg"} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-data"
                  href="https://www.linkedin.com/in/diegoachury"
                >
                  <img src={"/linkedin.svg"} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-data"
                  href="https://medium.com/@diegoachury"
                >
                  <img src={"/medium.svg"} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=573007983173&text=Hola%20me%20gustaria%20tu%20ayuda%20en%20un%20proyecto"
                >
                  <img src={"/whatsapp-glyph-black.svg"} alt="" />
                </a>
              </div>
              <div className="content-btn">
                {/* <input placeholder="hola@emial.com" type="text" /> */}
                <a href="mailto:dachury@lubu.com.co" className="btn bt-prc">
                  <FormattedMessage id="heroPage.btnEmail" />
                  <MdSend size="24px" />
                </a>
                <Link className="btn bt-project" to="/projects/">
                  <FormattedMessage id="heroPage.btnProject" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="content-photo">
              <img src={"/fond2.png"} alt="imagen personal" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="workflow">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="copy-align">
              <div className="copy">
                <p className="sub">WORKFLOW</p>
                <p className="title">
                  <FormattedMessage id="workflow.title" />
                </p>
                <p className="desc">
                  <FormattedMessage id="workflow.description" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="cont-box">
              <div className="card-box">
                <p className="title">
                  <FormattedMessage id="workflow.titleProto" />
                </p>
                <p className="desc">
                  <FormattedMessage id="workflow.descProto" />
                </p>
              </div>
              <div className="card-box">
                <p className="title">
                  <FormattedMessage id="workflow.titleProduct" />
                </p>
                <p className="desc">
                  <FormattedMessage id="workflow.descProduct" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="cont-box">
              <div className="card-box">
                <p className="title">
                  <FormattedMessage id="workflow.titleRemoto" />
                </p>
                <p className="desc">
                  <FormattedMessage id="workflow.descRemoto" />
                </p>
              </div>
              <div className="card-box">
                <p className="title">
                  <FormattedMessage id="workflow.titleDesign" />
                </p>
                <p className="desc">
                  <FormattedMessage id="workflow.descDesign" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="projects">
      <div className="cont-pro">
        <div className="copy">
          <p className="sub">
            <FormattedMessage id="quotation.subTitle" />
          </p>
          <p className="title">
            <FormattedMessage id="quotation.title" />
          </p>
          <div className="cont-btn">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://api.whatsapp.com/send?phone=573007983173&text=Hola%20me%20gustaria%20tu%20ayuda%20en%20un%20proyecto"
              className="btn bt-contact"
            >
              <FormattedMessage id="quotation.btn" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default injectIntl(IndexPage)
